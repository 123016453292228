import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { patchState, signalStore, withState } from '@ngrx/signals';
import { fromEvent } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NetworkStore extends signalStore({ protectedState: false }, withState({ isOnline: true })) {
  private onlineSubscription = fromEvent(window, 'online')
    .pipe(takeUntilDestroyed())
    .subscribe(() => this.online());

  private offlineSubscription = fromEvent(window, 'offline')
    .pipe(takeUntilDestroyed())
    .subscribe(() => this.offline());

  online(): void {
    patchState(this, { isOnline: true });
  }

  offline(): void {
    patchState(this, { isOnline: false });
  }
}
